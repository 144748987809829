<template lang="pug">
	div
		.mainbox
			v-container.main-container
				v-row(style='display: flex; justify-content: center; max-width: 800px')
					v-col.content-container(
						cols='12',
						lg='12',
						md='12',
						sm='12',
						xs='6',
						style='display: flex; flex-direction: column; justify-content: center; padding: 0'
					)
						.text-container Подтверждение регистрации пользователя: {{ login }}
						.input-container
							input.input(
								type='password',
								@keyup.enter='register',
								placeholder='Пароль',
								v-model='password'
							)
						v-btn(
							block,
							tile,
							@keyup.enter='register',
							v-on:click='register',
							:loading='isLoading',
							style='background-color: rgba(72, 92, 170, 1)'
						) Сохранить
</template>

<script>
import {ACTIVATE_USER} from '@/store/const/user'
import {mapActions, mapGetters} from 'vuex'
import loading_mixin from '@/mixins/loading_mixin';

export default {
	mixins: [loading_mixin],
	data: function () {
		return {
			password: '',
		};
	},
	computed: {
		...mapGetters(['loading', 'error_code']),
		login() {
			return this.$route.query['login'];
		},
		isLoading() {
			return this.loading(ACTIVATE_USER) === 'loading'
		},
		errorCode() {
			return this.error_code(ACTIVATE_USER)
		},
	},
	methods: {
		...mapActions('User', {
			ActivateUser: ACTIVATE_USER
		}),
		async register() {
			try {
				await this.ActivateUser({
					token: this.$route.query['token'],
					password: this.password
				})
				
				if (this.isError(ACTIVATE_USER)) {
					const message = this.errorMessage(ACTIVATE_USER);
					if (this.errorCode === 400 && message === 'token') {
						this.$notify({
							type: 'error',
							title: 'Ошибка в создании пароля',
							text: 'Некорректный токен (не существует или уже был активирован ранее)',
						});
					} else if (this.errorCode === 400 && message === 'password') {
						this.$notify({
							type: 'error',
							title: 'Ошибка в создании пароля',
							text: 'Введите более сложный пароль.',
						});
					} else {
						console.log('ERROR SAVE PASS', this.errorCode, message)
						this.$notify({
							type: 'error',
							title: 'Ошибка',
							text: 'Не известная ошибка',
						});
					}
				} else {
					this.$notify({
						type: 'success',
						text: 'Регистрация успешно пройдена',
					});
					
					setTimeout(() => {
						this.$router.push({name: 'AgencyList'});
					}, 1000);
				}
			} catch (e) {
				console.log('ERROR', e);
				this.$notify({
					type: 'error',
					title: 'Ошибка',
					text: 'Ошибка в создании пароля',
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
html {
	overflow-y: auto !important;
}

.mainbox {
	width: 100vw;
	height: 100vh;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	background: url('~@/assets/img/background/lefttopback.svg'), url('~@/assets/img/background/rightbottomback.svg'),
	linear-gradient(90deg, rgba(51, 51, 51, 0.8) 0%, rgba(51, 51, 51, 0.8) 0%), url('~@/assets/img/background/bg.jpg');
	background-size: 30%, 30%, cover, cover;
	background-color: var(--v-secondary-base);
	background-position: 0 0, 100% 100%, 0 0, 0 0;
	background-repeat: no-repeat;
	
	.main-container {
		align-self: center;
		display: flex;
		justify-content: center;
		
		.text-container {
			width: 100%;
			display: flex;
			justify-content: center;
			color: #fff;
			font-size: 22px;
			font-weight: bold;
		}
		
		.input-container {
			margin-top: 30px;
			
			.input {
				margin-bottom: 10px;
				width: 100%;
				height: 35px;
				padding: 8px 0 8px 13px;
				display: block;
				background-color: rgba(204, 204, 204, 0.5);
				color: var(--v-fontcolor-base);
				font-style: normal;
				font-weight: 300;
				font-size: 20px;
				line-height: 18px;
				border-radius: 4px;
			}
			
			.input::placeholder {
				color: var(--v-fontcolor-base);
				font-size: 15px;
			}
		}
		
		.v-btn {
			width: 100%;
			background-color: rgba(72, 92, 170, 1);
			color: var(--v-fontcolor-base);
			margin-top: 15px;
			border: none;
			border-radius: 4px;
			font-size: 15px;
			line-height: 18px;
		}
	}
}

@media (max-width: 375px) {
	body {
		background-size: 70%, 70%, cover, cover;
	}
}

@media (min-width: 375px) and (max-width: 599px) {
	body {
		background-size: 60%, 60%, cover, cover;
	}
}

@media (min-width: 600px) and (max-width: 1064px) {
	body {
		background-size: 45%, 45%, cover, cover;
	}
}

@media (min-width: 1065px) and (max-width: 1263px) {
	body {
		background-size: 40%, 40%, cover, cover;
	}
}
</style>
