import { mapGetters } from "vuex";

export default {
  data() {
    return {
      actions: [],
    };
  },
  computed: {
    ...mapGetters(["loading", "error"]),
    isLoading() {
      let is_loading = false;
      this.actions.map((action) => {
        if (this.loading(action) === "loading") {
          is_loading = true;
        }
      });
      return is_loading;
    },
  },
  methods: {
    isError(method_name) {
      return this.loading(method_name) === "error";
    },
    errorMessage(method_name) {
      return this.error(method_name);
    },
  },
};